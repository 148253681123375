@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Quicksand', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  padding: 20px;
  font-size: 1.1em;
  ;
}

header .navbar-wrapper {
  display: flex;
  justify-content: space-between
}

header .brand-link {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

header nav {
  display: flex;
  align-items: center;
}

header nav a {
  text-decoration: none;
  color: black;
  margin: 0 1em;
  text-transform: uppercase;
  font-size: .8em;
}

header nav a:hover {
  color: #5C4AC9;
}

html, body, div#root {
  height: 100%;
}

/* #wordSelectGameWrapper {
  background-color: blue;
  height: 100%;
  position: absolute;
} */

.App {
  display: flex;
  height: 100%;
  flex-direction: column;
}

#wordSelectGameWrapper {
  flex: 1 1;
  /* background-color: blue; */
  display: flex;
  ;
  justify-content: center;
  flex-direction: column;
}

#wordSelectGame {
  /* background-color: red; */
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

#fromToPrompt {
  margin: 20px 0;
}

#fromToPrompt div {
  margin: 5px auto;
  text-align: center;
}

#fromToPrompt p {
  display: inline-block;
  width: 100px;
  margin: 0 .15em;
  font-size: 2em;
  letter-spacing: -0.02em;
}

#fromToPrompt p.fromToPromptLeft {
  text-align: right;
}

#fromToPrompt p.fromToPromptRight {
  font-weight: 700;
  text-align: left;
}

#wordList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#wordList button {
  font-family: 'Quicksand', 'Helvetica Neue', sans-serif;
  border: 0;
  background-color: #F2F2F2;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 8px;
  font-size: 1.05em;
  font-weight: 600;
  color: #555;
}

#wordList button:enabled:hover {
  background-color: #D3D5F9;
  color: #5c4ac9;
}

#wordList button:disabled {
  color: #a5a5a5;
}

#wordList button.correctWord:disabled {
  background-color: #D3D5F9;
  color: white;
  border-color: #D3D5F9;
}

#wordList button.correctWord {
  /* background-color: #5c4ac9; */
  /* color: white; */
  border: 2px solid #5c4ac9;
  color: #5c4ac9;
  padding: 2px 7px;
  box-sizing: content-box;
}

/* #wordList button.correctWord:hover {
  background-color: #5c4ac9;
  color: #D3D5F9;
} */

#surrenderButton {
  background: none;
  border: none;
  font-size: 1.2em;
  margin: 8px auto;
  display: block;
  color: #5C4AC9;
}

#surrenderButton:disabled {
  color: #D3D5F9;
}

#surrenderHover {
  display: none;
}

#surrenderButton:hover #surrenderDefault {
  display: none;
}

#surrenderButton:hover #surrenderHover {
  display: inline-block;
}

#wonGame {
  position: fixed;
  width: 400px;
  height: 180px;
  top: 50%;
  left: 50%;
  margin-top: -90px;
  /* Negative half of height. */
  margin-left: -200px;
  /* Negative half of width. */
  background-color: #5C4AC9;
  border-radius: 10px;
  color: white;
  -webkit-filter: drop-shadow(0 0 12px rgba(0, 0, 0, .4));
          filter: drop-shadow(0 0 12px rgba(0, 0, 0, .4));
}

#wonGame.wonGameHidden {
  display: none;
}

#wonGame {
  padding: 20px;
  box-sizing: border-box;
}

#wonGame h1 {
  margin-top: 5px;
}

#wonGame button {
  text-align: center;
  background-color: white;
  border: 0;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  padding: 5px 18px;
  font-family: 'Quicksand';
  width: 100%;
}

#wonGame button:hover {
  background-color: #D3D5F9;
}

#wordHistory {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#wordHistory ul {
  list-style: none;
  padding: 50px;
  max-width: 1500px;
  margin: 0 auto;
}

#wordHistory li {
  display: inline;
  margin: 0 0px;
}

#wordHistory li svg {
  margin: 0 7px 0 5px;
  color: #dddddd;
}

#content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}
